import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Popover } from '@headlessui/react'
import { MenuIcon } from '@heroicons/react/outline'
import { Account } from '@Types/account/Account'
import Typography from 'components/commercetools-ui/typography'
import { GLOBAL_SITE } from 'helpers/constants/envVariables'
import { getMappedLocale } from 'helpers/localeHelpers'
import { headerNavigation } from 'helpers/mocks/mockData'
import { isLiveReference, Reference, ReferenceLink } from 'helpers/reference'
import { GlobalRegionAndLanguageJson, RegionAndLanguage } from 'types/header'
import Image, { MediaItemWithMods } from 'frontastic/lib/image'
import LanguageSwitcher from '../language-switcher'
import AccountButton from './account-button'
import CartButton from './cart-button'
import HeaderMenu from './header-menu'

export interface Link {
  name: string
  reference: Reference
}

export interface HeaderProps {
  tagline?: string
  links: Link[]
  cartItemCount: number
  wishlistItemCount?: number
  logo: MediaItemWithMods
  rightLogo: MediaItemWithMods
  logoLink: Reference
  account: Account
  accountLink: Reference
  wishlistLink?: Reference
  cartLink: Reference
  previewId: string
  regionLanguageSelectorTitle: string
  regionAndLanguageGroup: RegionAndLanguage[]
  regionLanguageGlobalSelectorJson?: GlobalRegionAndLanguageJson
  showRegionAndLanguageSelector?: boolean
  showRegionAndLanguageSelectorInHeader?: boolean
  showConsent?: boolean
}

const Header: React.FC<HeaderProps> = ({
  tagline,
  links,
  cartItemCount,
  logo,
  rightLogo,
  logoLink,
  account,
  accountLink,
  cartLink,
  previewId,
  regionLanguageSelectorTitle,
  regionAndLanguageGroup,
  regionLanguageGlobalSelectorJson,
  showRegionAndLanguageSelector = true,
  showRegionAndLanguageSelectorInHeader = false,
  showConsent,
}) => {
  const [open, setOpen] = useState(false)
  const onClickHamburgerMenu = () => {
    setOpen((prev) => !prev)
  }

  const router = useRouter()
  useEffect(() => {
    if (!showRegionAndLanguageSelectorInHeader && router?.locale !== router?.defaultLocale) {
      router.push(router.asPath, router.asPath, { locale: router.defaultLocale })
    }
  }, [])

  return (
    <>
      {/* Mobile menu */}
      <HeaderMenu
        showConsent={showConsent}
        open={open}
        setOpen={setOpen}
        links={links}
        navigation={headerNavigation}
        previewId={previewId}
      />

      {/* Desktop menu */}
      <header className="header-nerivio bg-gradient-header">
        {tagline && (
          <p className="bg-primary-400 flex items-center justify-center px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
            <Typography>{tagline}</Typography>
          </p>
        )}

        <nav
          aria-label="Top"
          className="main_homeheader mx-auto max-w-full border-b border-gray-200 px-space-inside-header-footer-sm lg:px-space-inside-header-footer-lg"
        >
          {/* Secondary navigation */}
          <div className="h-full">
            <div className="flex h-header-height-sm items-center justify-between lg:h-header-height-lg">
              {/* Logo */}
              <div className="flex items-center lg:hidden">
                <button
                  type="button"
                  className="hamburger-menu flex h-7 w-7 items-center justify-center bg-none text-white sm:h-10 sm:w-10"
                  onClick={onClickHamburgerMenu}
                  disabled={open}
                >
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-7 w-7 sm:h-10 sm:w-10" aria-hidden="true" />
                </button>
              </div>
              <ReferenceLink target={logoLink} className="mr-auto ml-2.5 flex lg:m-0">
                <span className="sr-only">Logo</span>
                <div className="header-logo absolute-to-relative-img">
                  <Image
                    media={logo}
                    layout="fill"
                    alt={logo?.['title']?.[getMappedLocale()] ?? ''}
                    loading={'eager'}
                    sizes="(max-width: 639x) 200px, (max-width: 1023px) 334px, 350px"
                  />
                </div>
              </ReferenceLink>

              {/* Mega menus */}
              {GLOBAL_SITE !== 'true' && (
                <>
                  <Popover.Group className="mega-menu hidden lg:ml-auto lg:block">
                    {links
                      .filter((link) => previewId || isLiveReference(link.reference))
                      .map((link, id) => (
                        <ReferenceLink key={id} target={link.reference} className="menu-item">
                          <Typography>{link.name}</Typography>
                        </ReferenceLink>
                      ))}
                  </Popover.Group>

                  <div className="flex items-center justify-between gap-3 lg:gap-10">
                    {showRegionAndLanguageSelectorInHeader && (
                      <LanguageSwitcher
                        className="p-0 md:px-0"
                        regionLanguageSelectorTitle={regionLanguageSelectorTitle}
                        regionAndLanguageGroup={regionAndLanguageGroup}
                        showRegionAndLanguageSelector={showRegionAndLanguageSelector}
                      />
                    )}
                    <AccountButton account={account} accountLink={accountLink} />
                    <CartButton cartItemCount={cartItemCount} cartLink={cartLink} />
                    {rightLogo?.media && (
                      <div className="relative hidden aspect-drreddy-logo w-[113px] lg:flex">
                        <Image
                          media={rightLogo}
                          layout="fill"
                          objectFit="contain"
                          alt={rightLogo?.['title']?.[getMappedLocale()] ?? ''}
                          loading="lazy"
                          sizes="113px"
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </nav>
      </header>
      {GLOBAL_SITE === 'true' && (
        <div className="global-region-selector h-full">
          <LanguageSwitcher
            className="h-full w-full p-0 md:px-0"
            regionLanguageSelectorTitle={regionLanguageSelectorTitle}
            regionAndLanguageGroup={regionAndLanguageGroup}
            regionLanguageGlobalSelectorJson={regionLanguageGlobalSelectorJson}
            alwaysDisplay
          />
        </div>
      )}
    </>
  )
}

export default Header
