import React from 'react'
import { useRouter } from 'next/router'
import { mutate } from 'swr';
import Dropdown, { DropdownProps } from 'components/commercetools-ui/dropdown'
import { formatLocaleName } from 'helpers/utils/formatLocaleName'
import { GlobalRegionAndLanguageJson, RegionAndLanguage } from 'types/header';

interface Props {
  className: string;
  regionLanguageSelectorTitle: string
  regionAndLanguageGroup: RegionAndLanguage[]
  alwaysDisplay?: boolean
  regionLanguageGlobalSelectorJson?: GlobalRegionAndLanguageJson
  showRegionAndLanguageSelector?: boolean
}

const LanguageSwitcher: React.FC<Props> = ({ className, regionLanguageSelectorTitle, regionAndLanguageGroup, alwaysDisplay, regionLanguageGlobalSelectorJson, showRegionAndLanguageSelector }) => {
  const router = useRouter()

  const items: DropdownProps['items'] = router.locales.map((locale) => {
    return { label: formatLocaleName(locale), value: locale }
  })

  const onSelectLanguage = async (selectedValue: string) => {
    await router.push(router.asPath, router.asPath, { locale: selectedValue })
    // Mutate to get corresponding language in cart
    mutate('/action/cart/getCart')
  }

  return (
    <div className={`relative inline-block text-left ${className}`}>
      <Dropdown
        regionLanguageSelectorTitle={regionLanguageSelectorTitle}
        regionAndLanguageGroup={regionAndLanguageGroup}
        showRegionAndLanguageSelector={showRegionAndLanguageSelector}
        defaultValue={router?.locale}
        items={items}
        onChange={onSelectLanguage}
        regionLanguageGlobalSelectorJson={regionLanguageGlobalSelectorJson}
        alwaysDisplay={alwaysDisplay}
      />
    </div>
  )
}

export default LanguageSwitcher
