import { AUTH0_LOCALES, DEFAULT_LOCALE, SITE_URL} from 'helpers/constants/envVariables'
import { LANGUAGE_PARAM } from './constants/auth0'
import { EMAIL_VERIFICATION, WELCOME_PAGE } from './constants/statisURLs'

export const getLocaleUri = (locale?: string): string => {
  if (locale === DEFAULT_LOCALE) {
    return ''
  }

  return `/${locale}`
}

type RedirectOptions = {
  includeLanguageParam?: boolean
  openLoginPage?: boolean
}

const guessAuth0Locale = (locale?: string) => {
  if (!locale) return null

  try {
    const authLocales = JSON.parse(AUTH0_LOCALES)
    return authLocales[locale] || locale
  } catch (error) {
    return locale
  }
}

export const redirectLoginUrl = (returnToUri?: string, locale?: string, options?: RedirectOptions) => {
  if (returnToUri) {
    if(options?.includeLanguageParam && !returnToUri.includes(`${LANGUAGE_PARAM}=`)) {
      returnToUri += (!returnToUri.includes('?') ? '?' : '&' ) + `${LANGUAGE_PARAM}=${locale}`
    }
    const openLoginPrompt = returnToUri.includes(EMAIL_VERIFICATION) || returnToUri.includes(WELCOME_PAGE) || options?.openLoginPage

    return `${SITE_URL}/api/auth/login?`
   + `returnTo=${encodeURIComponent(getLocaleUri(locale) + returnToUri)}`
   + `&locale=${guessAuth0Locale(locale)}`
   + `${openLoginPrompt ? `&prompt=login` : ''}`
  }

  return `${SITE_URL}/api/auth/login?locale=${locale}`
}

export const redirectLogoutUrl = (locale?: string) => {
  return `${SITE_URL}/api/auth/logout?locale=${locale}`
}
