import React, { Fragment, useState } from 'react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import Typography from 'components/commercetools-ui/typography'
import { redirectLogoutUrl } from 'helpers/auth0Helper'
import { ZOHO_URL } from 'helpers/constants/envVariables'
import { MY_ACOUNT } from 'helpers/constants/statisURLs'
import { useFormat } from 'helpers/hooks/useFormat'
import { isLiveReference, ReferenceLink } from 'helpers/reference'
import { getZohoLink } from 'helpers/zohoHelpers'
import { useAccount, useDarkMode } from 'frontastic'
import Loader from '../loader'
import { Link } from './index'

interface HeaderMenuProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  navigation: any
  links: Link[]
  previewId?: string
  showConsent?: boolean
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({ open, setOpen, navigation, links, previewId, showConsent }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { isValidating, loggedIn } = useAccount()
  // const hash = window.location.hash || '#'
  const path = useRouter().asPath?.split('?')[0]
  //Darkmode
  const { mode, theme } = useDarkMode()

  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' })
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' })

  const closeMenu = () => setOpen(false)
  const router = useRouter()

  const navLinkComponent = (href: string, label: string, openNewtab = false) => (
    <div
      className={classNames('nav__item', {
        active: path === href,
      })}
      onClick={closeMenu}
    >
      <NextLink href={href}>
        <a className="nav__link" {...(openNewtab && { target: '__blank' })}>
          {label}
        </a>
      </NextLink>
    </div>
  )

  const navAccountMenu = [
    {
      key: 'nav-account-menu-profile',
      component: navLinkComponent(MY_ACOUNT.PROFILE, formatAccountMessage({ id: 'profile.details' })),
    },
    {
      key: 'nav-account-menu-addresses',
      component: navLinkComponent(MY_ACOUNT.ADDRESSES, formatAccountMessage({ id: 'addresses' })),
    },
    {
      key: 'nav-account-menu-orders',
      component: navLinkComponent(MY_ACOUNT.ORDERS, formatAccountMessage({ id: 'orders' })),
    },
    ...(showConsent
      ? [
          {
            key: 'nav-account-menu-consent',
            component: navLinkComponent(MY_ACOUNT.SUPPORT, formatAccountMessage({ id: 'patient.support.program' })),
          },
        ]
      : []),
  ]

  const ZohoHelpComponent = ZOHO_URL
    ? navLinkComponent(getZohoLink(router.locale), formatAccountMessage({ id: 'account.help' }), true)
    : null

  const onClickLogout = () => {
    setLoading(true)
    location.assign(redirectLogoutUrl(router.locale))
  }

  return (
    <>
      {loading && <Loader />}
      <Transition.Root show={open} as={Fragment}>
        <Dialog className={`mobile-flyout-menu ${mode} ${theme}`} onClose={closeMenu}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="mobile-flyout-menu__backdrop" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="mobile-flyout-menu__body">
              <div className="mobile-flyout-menu__close flex">
                <button
                  type="button"
                  className="my-3 ml-auto mr-5 inline-flex items-center justify-center text-green-dark"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">{formatMessage({ id: 'menu.close', defaultMessage: 'Close menu' })}</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <nav className="nav__wrapper">
                {links
                  .filter((link) => previewId || isLiveReference(link.reference))
                  .map((link) => (
                    <div key={link.name} className="nav__item" onClick={closeMenu}>
                      <ReferenceLink target={link.reference} className="nav__link">
                        <Typography>{link.name}</Typography>
                      </ReferenceLink>
                    </div>
                  ))}
                {!isValidating && loggedIn && (
                  <>
                    {navAccountMenu.map((item) => (
                      <React.Fragment key={item.key}>{item.component}</React.Fragment>
                    ))}
                    {ZohoHelpComponent}
                    <div className="nav__item" onClick={onClickLogout}>
                      <a onClick={onClickLogout} className="nav__link">
                        {formatAccountMessage({ id: 'signout' })}
                      </a>
                    </div>
                  </>
                )}
              </nav>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default HeaderMenu
