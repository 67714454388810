import React, { Fragment, useState } from 'react'
import { useRouter } from 'next/router'
import { useUser } from '@auth0/nextjs-auth0/client'
import { Menu, Transition } from '@headlessui/react'
import { Account } from '@Types/account/Account'
import { redirectLogoutUrl, redirectLoginUrl } from 'helpers/auth0Helper'
import { useFormat } from 'helpers/hooks/useFormat'
import { Reference, ReferenceLink } from 'helpers/reference'
import Loader from '../loader'
import { CART } from 'helpers/constants/statisURLs'

interface AccountButtonProps {
  accountLink: Reference
  account: Account
}

const AccountButton: React.FC<AccountButtonProps> = ({ accountLink, account }) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' })
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const clickOnLink = () => {
    setLoading(true)
  }

  const { user } = useUser()

  return (
    <>
      {loading && <Loader />}
      <div className="relative flex items-center">
        {account ? (
          <Menu>
            <div className="relative flex space-x-8">
              <Menu.Button className="header-icon icon-user">
                <span className="sr-only">Account</span>
              </Menu.Button>
              {/* <div className="loggedin-user absolute -right-[1px] -bottom-[2px] h-[9px] w-[9px] rounded-md bg-green-700"></div> */}
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="account-flyout dark:bg-primary-400 absolute right-0 top-6 z-50 mt-2 w-fit origin-top-right overflow-hidden rounded-md bg-white shadow-sm ring-1 ring-primary focus:outline-none dark:shadow-3xl">
                <div className="py-0">
                  <Menu.Item as="button">
                    <ReferenceLink
                      target={accountLink}
                      className="text-primary-400 block w-full cursor-pointer whitespace-nowrap py-2 px-4 text-left text-sm hover:underline"
                    >
                      {formatAccountMessage({ id: 'my.account', defaultMessage: 'My Account' })}
                    </ReferenceLink>
                  </Menu.Item>
                  {account && (
                    <Menu.Item as="button" className="w-full">
                      <a
                        onClick={clickOnLink}
                        data-cy="logout-link"
                        href={redirectLogoutUrl(router.locale)}
                        className="block w-full cursor-pointer whitespace-nowrap py-2 px-4 text-left text-sm hover:underline"
                      >
                        {formatAccountMessage({ id: 'signout', defaultMessage: 'Logout' })}
                      </a>
                    </Menu.Item>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        ) : (
          <div className="flex space-x-8">
            <div className="flex">
              <a
                onClick={clickOnLink}
                href={redirectLoginUrl(router?.asPath, router.locale, {
                  includeLanguageParam: true,
                  openLoginPage: !user?.email_verified,
                })}
              >
                <span className="header-icon icon-user">
                  <span className="sr-only">Account</span>
                </span>
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default AccountButton
