export const BEARERTOKEN_USERNAME = process.env.NEXT_PUBLIC_BEARERTOKEN_USERNAME
export const BEARERTOKEN_PASSWORD = process.env.NEXT_PUBLIC_BEARERTOKEN_PASSWORD
export const BEARERTOKEN_BASE_URL = process.env.NEXT_PUBLIC_BEARERTOKEN_BASE_URL
export const FRONTASTIC_HOST = process.env.NEXT_PUBLIC_FRONTASTIC_HOST
export const BACKEND_BASE_URL = process.env.NEXT_PUBLIC_BACKEND_BASE_URL
export const COMMERCETOOLS_BASE_URL = process.env.NEXT_PUBLIC_COMMERCETOOLS_BASE_URL
export const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID
export const VOCHERIFY_CLIENT_ID = process.env.NEXT_PUBLIC_VOCHERIFY_CLIENT_ID
export const VOCHERIFY_SECRET_KEY = process.env.NEXT_PUBLIC_VOCHERIFY_SECRET_KEY
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL
export const PAYPAL = process.env.NEXT_PUBLIC_PAYPAL
export const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_DEFAULT_LOCALE
export const GLOBAL_SITE = process.env.NEXT_PUBLIC_GLOBAL_SITE
export const COUNTRY_CODE = process.env.NEXT_PUBLIC_COUNTRY_CODE
export const ZOHO_URL = process.env.NEXT_PUBLIC_ZOHO_URL
export const AUTH0_LOCALES = process.env.NEXT_PUBLIC_AUTH0_LOCALES
