import React from 'react'
import classnames from 'classnames'
import {
  FieldValue,
  FieldValues,
  FormState,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form'

type FormRadioProps = {
  readonly name: string
  readonly label: string | JSX.Element
  readonly header?: string | JSX.Element
  readonly dataCy?: string
  readonly value?: string | number
  readonly disabled?: boolean
  readonly onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
  readonly containerClassName?: string
  readonly labelClassName?: string
  readonly inputClassName?: string
  selected?: boolean // new prop for selected state
  readonly defaultChecked?: boolean
  // Props for react-hook-form
  readonly formRegister?: UseFormRegister<FieldValue<FieldValues>>
  readonly formState?: FormState<FieldValue<FieldValues>>
  readonly formInputOptions?: RegisterOptions
}

const RadioForm: React.FC<FormRadioProps> = ({
  name,
  label,
  header = null,
  dataCy,
  disabled = false,
  value,
  containerClassName,
  inputClassName,
  labelClassName,
  selected,
  defaultChecked,
  formRegister,
  formInputOptions = {},
  onClick,
}) => {
  const isUseHookForm = !!(formRegister)

  return (
    <div data-cy={dataCy} className={classnames(
      containerClassName,
      'checkbox-radio-wrapper',
      'bg-secondary-light text-green-dark rounded-xl py-2 px-4',
    )}>
      <input
        className={classnames(inputClassName, 'radio')}
        {...(isUseHookForm && formRegister(name, formInputOptions))}
        id={`${name}_${value}`}
        name={name}
        type="radio"
        disabled={disabled}
        value={value}
        checked={selected}
        defaultChecked={defaultChecked}
        onClick={onClick}
      />
      <div className='checkbox-label-combination w-full'>
        {header}
        <label htmlFor={`${name}_${value}`} className={classnames(labelClassName)}>{label}</label>
      </div>
    </div>
  )
}
export default RadioForm
